import tw from "tailwind.macro";
// import styled from "@emotion/styled";

import { css, keyframes } from "@emotion/core";

import { md } from "../styles/breakpoints";

export const styles = {
  wrap: css`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 700px;
    margin: 0 auto;
  `,
  imgbox: css`
    position: relative;
  `,
  phoneBox: css`
    ${tw`relative block mx-auto`};
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.15);
    width: 235px;
    height: 468px;
    border-radius: 50px;
    border-color: transparent;

    @media (${md}) {
      position: absolute;
      width: 165px;
      height: 328px;
      bottom: -24px;
      left: -16px;
    }
  `,
  iphone: tw`relative z-10`,
  laptop: css`
    display: none;

    @media (${md}) {
      display: block;
    }
  `,
  screen: css`
    position: absolute;
    transform: translateZ(0);
  `,
  screenMask: css`
    ${tw`absolute z-10`};
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    overflow: hidden;
  `,
  ctaWrap: css`
    ${tw`flex flex-col items-center justify-center text-center mt-12`};
    ${tw`xl:absolute xl:mt-auto`};

    @media (${md}) {
      position: static;
      margin-top: 60px;
    }
  `,
  ctaTxt: tw`text-lg my-4 px-4 md:px-0 text-lg leading-normal`,
  mobileOnly: tw`md:hidden`,
  notMobile: tw`hidden md:block`,
  getStarted: tw`mb-16`,
  badges: css`
    ${tw`flex flex-col items-center justify-center`}

    @media (${md}) {
      flex-direction: row;
    }
  `,
  iosBadge: css`
    ${tw`m-2`}
    width: 170px;
  `,
  androidBadge: css`
    ${tw`m-2`}
    width: 170px;
  `,
};

const percent = (v) => `${(v * 100).toFixed(2)}%`;

export const animationLoop = (screens, transition, duration) => {
  const totalTime = (duration + transition) * screens.length;

  const tl = (time) => percent(time / totalTime);

  const fadeOutStart = duration;
  const fadeOutEnd = fadeOutStart + transition;
  // const fadeInStart = totalTime - transition;

  const animation = keyframes`
    0%                                     { opacity: 0; }
    ${tl(transition)}, ${tl(fadeOutStart)} { opacity: 1; }
    ${tl(fadeOutEnd)}, 100%                { opacity: 0; }
  `;

  return screens.map((_, idx) => {
    return css`
      opacity: 0;
      animation: ${animation} ${totalTime}s linear infinite;
      animation-delay: ${(duration + transition) * idx}s;
      animation-fill-mode: forwards;
    `;
  });
};

// export const animationLoop = (screens, transition, duration) => {
//   const last = screens.length - 1;
//   const loopTime = (duration + transition) * screens.length;
//   const tl = (time) => percent(time / loopTime);

//   const outStart1 = duration;
//   const outEnd1 = outStart1 + transition;
//   const inStart1 = loopTime - transition;

//   const animFirst = keyframes`
//     0%, ${tl(outStart1)}     { opacity: 1; }
//     ${outEnd1}, ${inStart1}  { opacity: 0; }
//     100%                     { opacity: 1; }
//   `;

//   const outStart1 = duration;
//   const outEnd1 = outStart1 + transition;
//   const inStart1 = loopTime - transition;

//   return screens.map((_, idx) => {
//     const delay = idx * (duration + transition);

//     // We want the slide fully visible at the delay time.
//     const inStart = delay - transition;
//     const inEnd = delay;

//     const outStart = inEnd + duration;
//     const outEnd = outStart + transition;

//     const between = keyframes`
//       0%, ${tl(inStart)}             { opacity: 0; }
//       ${tl(inEnd)}, ${tl(outStart)}  { opacity: 1; }
//       ${tl(outEnd)}, 100%            { opacity: 0; }
//     `;

//     const first = idx === 0 && animFirst;
//     const last = idx === screens.length - 1 && animLast;
//     const anim = first || last || between;

//     return css`
//       animation-delay: ${idx * (transition + duration)}s;
//       animation: ${anim} ${loopTime}s infinite;
//     `;
//   });
// };
