import React from "react";
import { css } from "@emotion/core";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Image from "./Image";
import _get from "lodash.get";

import Btn from "./Btn";
import StoreBadge from "./StoreBadge";

import iphone from "../images/phone-screens/iphone_body.png";
import screen1 from "../images/phone-screens/screen1.jpg";
import screen2 from "../images/phone-screens/screen2.jpg";
import screen3 from "../images/phone-screens/screen3.jpg";
import screen4 from "../images/phone-screens/screen4.jpg";

import { styles, animationLoop } from "./GetNotified.styles";

export const LAPTOP_IMAGE_QUERY = graphql`
  query {
    laptopImage: file(relativePath: { eq: "app-laptop2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const screens = [screen1, screen2, screen3, screen4];
const animationFx = animationLoop(screens, 0.2, 2.5);

const IPhoneScreen = ({ image, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 480 957"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <mask id="iphone-screen" fill="#fff">
        <path d="M31 76.7v797.6c0 29.8 17.8 47.7 47.3 47.7h315c29.6 0 47.4-18 47.4-47.7V76.7H31z" />
      </mask>
      <path fill="#F6F6F6" d="M.5.5h485v956H.5z" mask="url(#iphone-screen)" />
      <image
        mask="url(#iphone-screen)"
        xlinkHref={image}
        x="29"
        y="77"
        width="413"
        height="852"
      ></image>
    </g>
  </svg>
);

const GetNotified = ({ id }) => {
  const { laptopImage } = useStaticQuery(LAPTOP_IMAGE_QUERY);

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={screen1} />
        <link
          rel="preload"
          as="image"
          imagesrcset={_get(laptopImage, "childImageSharp.fluid.srcSet")}
          imagesizes={_get(laptopImage, "childImageSharp.fluid.sizes")}
        />
        <link
          rel="preload"
          as="image"
          imagesrcset={_get(laptopImage, "childImageSharp.fluid.srcSetWebp")}
          imagesizes={_get(laptopImage, "childImageSharp.fluid.sizes")}
        />
      </Helmet>
      <div css={styles.wrap} id={id}>
        <div css={styles.imgbox}>
          <Image {...laptopImage} css={styles.laptop} alt="Desktop" />
          <div css={styles.phoneBox}>
            <img src={iphone} css={styles.iphone} alt="Mobile" />
            <div css={styles.screenMask}>
              {screens.map((screen, idx) => (
                <IPhoneScreen
                  key={idx}
                  image={screen}
                  css={css`
                    ${styles.screen};
                    ${animationFx[idx]}
                  `}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div css={styles.ctaWrap}>
        <Btn
          css={[styles.getStarted, styles.notMobile]}
          center
          href="https://masterclass.ted.com/web/"
          label="Get Started"
        />
        <p css={[styles.ctaTxt, styles.mobileOnly]}>Ready to start learning?</p>
        <p css={[styles.ctaTxt, styles.notMobile]}>
          You can also download the app.
        </p>
        <div css={styles.badges}>
          <StoreBadge ios imgProps={{ width: 170, css: styles.iosBadge }} />
          <StoreBadge
            android
            imgProps={{ width: 170, css: styles.androidBadge }}
          />
        </div>
      </div>
    </>
  );
};

export default GetNotified;
