import React from "react";

import useIndexPageData from "../hooks/useIndexPageData";
import useUTMRedirect from "../hooks/useUTMRedirect";

import posterImg from "../images/posters/TED_Masterclass_Course_Preview.jpg";

// import Btn from "../components/Btn";
import ContactBlock from "../components/ContactBlock";
import CoursePreviewBlock from "../components/CoursePreviewBlock";
import CTASimple from "../components/CTASimple";
import Footer from "../components/Footer";
import GetNotified from "../components/GetNotified";
import Header from "../components/Header";
import Layout from "../components/layout";
import Testimonials from "../components/Testimonials";
import SectionTitle from "../components/section_title";
import SplitBox from "../components/SplitBox";

import tw from "tailwind.macro";

const styles = {
  intro: tw`mt-12 xl:mt-24 mb-12 max-w-4xl mx-auto px-5`,
  pgBG: tw`bg-grey-lightest overflow-hidden`,
};

const IndexPage = ({ intro, hero1, hero2, ctaBlock }) => (
  <Layout>
    <Header />
    <div css={styles.pgBG}>
      <div css={styles.intro}>
        <SectionTitle large {...intro} />
      </div>
      <GetNotified id="get-the-app" />
      <SplitBox reverse {...hero1} />
      <SplitBox {...hero2} />
      <Testimonials />
    </div>
    <CoursePreviewBlock
      id="course-preview"
      tagline="Take the first step towards mastering the art of public speaking by checking out TED Masterclass, TED’s newest mobile app."
      playerProps={{
        videoUrl: "https://www.youtube.com/watch?v=b7id4rzgKIM",
        poster: posterImg,
        dark: true,
      }}
    />
    <CTASimple
      grayBG
      {...ctaBlock}
      cta={{
        ...ctaBlock.cta,
        invert: true,
      }}
      cta2={{
        ...ctaBlock.cta2,
        invert: true,
      }}
    />
    <ContactBlock />
    <Footer />
  </Layout>
);

const IndexPageWithData = (props) => (
  <IndexPage {...useIndexPageData()} {...props} />
);

const IndexPageWithRedirect = (props) => {
  useUTMRedirect();
  return <IndexPageWithData {...props} />;
};

export default IndexPageWithRedirect;
